<template>
  <div
    v-show="showColors"
    class="product-intro__color j-expose__product-intro__color"
  >
    <div
      :class="[
        'product-intro__color-title',
        'j-expose__product-intro__color-title',
      ]"
    >
      <div
        v-show="currColorInfo.name"
        class="color-block"
        tabindex="0"
        :aria-label="currColorInfo.name + ': ' + currColorInfo.val"
      >
        <span class="title">{{ currColorInfo.name }}</span>
        <span class="color-999">{{ currColorInfo.val }}</span>
      </div>

      <ClientOnly>
        <div class="link-box">
          <span
            v-if="showFindMyShadeEntry"
            class="product-intro__color-shade"
            href="javascript:;"
            da-event-click="1-8-4-4"
            @click="handleClickFindMyShade"
          >{{ language.SHEIN_KEY_PC_19812 }}</span>
          <span
            v-if="showColorMoreIcon && supportLargeImageList"
            v-expose="{ id: '1-8-6-123' }"
            da-event-click="1-8-6-124"
            class="product-intro__color-shade"
            @click="showLargeImgDrawer=true"
          >{{ language.SHEIN_KEY_PC_24404 }}</span>
        </div>
      </ClientOnly>
    </div>

    <!-- skeleton -->
    <ColorSkeleton
      v-if="!isComplete"
      :colors-total="colorsTotal"
    />

    <ClientOnly>
      <div
        v-for="(pColors, pIndex) in newColorArr"
        :key="`product-intro__color_choose__${pIndex}`"
        :class="
          !showMoreColor &&
            pIndex === 3 &&
            newColorArr.length > 4 &&
            `${colorType}`
        "
        class="product-intro__color_choose fsp-element"
      >
        <div
          v-if="!showMoreColor && pIndex === 3 && newColorArr.length > 4"
          class="mask"
        ></div>
        <template
          v-if="
            (!showMoreColor && pIndex <= 3 && newColorArr.length > 4) ||
              showMoreColor ||
              newColorArr.length <= 4
          "
        >
          <s-popover
            v-for="(color, index) in pColors"
            ref="colorPopover"
            :key="index"
            trigger="hover"
            placemen="top-start"
            :prop-style="{
              padding: '16px',
              maxWidth: 'unset',
            }"
            :immediately-render="true"
            :empty-un-show="true"
            :append-to-body="true"
            :delay-render="300"
            :goods-id="color.goods_id"
            @opened="popoverOpened(color)"
          >
            <template #reference>
              <div
                v-enterkey
                :class="{
                  'product-intro__color-block': colorType == 'block',
                  'product-intro__color-block_active':
                    colorType == 'block' && color.goods_id === currentGoodsId,
                  'product-intro__color-radio': colorType == 'radio',
                  'product-intro__color-radio_active':
                    colorType == 'radio' && color.goods_id === currentGoodsId,
                  'product-intro__size-radio': colorType == 'text',
                  'product-intro__size-radio_active':
                    colorType == 'text' && color.goods_id === currentGoodsId,
                }"
                tabindex="0"
                :aria-label="color.attr_value"
                @click="handleChangeColor(color, false, index)"
              >
                <div
                  v-if="colorType != 'text'"
                  class="color-inner"
                >
                  <CropImageContainer
                    v-if="colorType === 'block' && colorConf.imgField === 'goods_thumb'"
                    :img-src="transformImg({ img: color.goods_color_image })"
                    ignore-img-ext
                    :fixed-ratio="fixedRatio"
                    :is-support-crop-image="isSupportCropImage"
                  />
                  <img
                    v-else
                    :src="transformImg({ img: color.goods_color_image })"
                  />

                  <span
                    v-if="color.isSoldoutColor"
                    class="product-intro__color-soldout"
                  ></span>
                  <div
                    v-if="colorType === 'block' && color.isSoldoutColor"
                    class="product-intro__color-soldout-mask"
                  >
                  </div>
                </div>
                <div
                  v-else
                  class="product-intro__size-radio-inner"
                >
                  {{ color.attr_value }}
                </div>
                <ClientOnly v-if="promotionTagInfos && promotionTagInfos[color.goods_id] && promotionTagInfos[color.goods_id].showVisible">
                  <div
                    v-if="promotionTagInfos[color.goods_id].tagType === 1 && promotionTagInfos[color.goods_id].unit_discount"
                    v-expose="labelExpose(color.goods_id)"
                    class="product-intro__tag-discount"
                  >
                    -{{ promotionTagInfos[color.goods_id].unit_discount }}%
                  </div>
                  <Icon 
                    v-else-if="promotionTagInfos[color.goods_id].tagType === 2"
                    v-expose="labelExpose(color.goods_id)"
                    class="product-intro__tag-promotion"
                    name="sui_icon_activity_16px" 
                    size="16px" 
                    :color=" IS_RW ? '#FC4070' : '#C44A01' "
                    :custom-style="{ lineHeight: '19.2px'}"
                  />
                </ClientOnly>
                <ClientOnly v-else>
                  <img
                    v-if="color.isEco && showEco"
                    class="color-evolu"
                    src="https://img.ltwebstatic.com/images3_ccc/2024/05/07/42/color_evolu_pc.png"
                  />
                  <img
                    v-else-if="color.isHot"
                    class="color-hot"
                    :src="cdnUrl + '/she_dist/images/color_hot-e5b559d042.png'"
                  />
                </ClientOnly>
              </div>
            </template>
            <div v-if="showAttrImgPopoverCache && color && color.mainSaleAttribute && color.mainSaleAttribute.attr_image">
              <div 
                v-if="color.mainSaleAttribute.attr_value" 
                class="product-intro__color-popoverTitle"
              >
                {{ color.mainSaleAttribute.attr_value }}
              </div>
              <img
                :src="transformImg({ img: color.mainSaleAttribute.attr_image })"
                class="product-intro__color-popoverImg"
              />
            </div>
          </s-popover>
        </template>
      </div>

      <div
        v-if="hasShowMoreEl"
        v-enterkey
        class="product-intro__color_showMore"
        :aria-label="
          language[!showMoreColor ? 'SHEIN_KEY_PC_20458' : 'SHEIN_KEY_PC_20457']
        "
        tabindex="0"
        @click="updateShowMore"
      >
        <span>
          {{
            language[
              !showMoreColor ? 'SHEIN_KEY_PC_20458' : 'SHEIN_KEY_PC_20457'
            ]
          }}
          <Icon
            v-if="showMoreColor"
            name="sui_icon_more_up_18px"
            size="16px"
          />
          <Icon
            v-else
            name="sui_icon_more_down_18px"
            size="16px"
          />
        </span>
      </div>
    </ClientOnly>

    <ClientOnly>
      <ColorPromotionEntry
        v-if="promotionTagInfos && promotionTagInfos[currentGoodsId] && promotionTagInfos[currentGoodsId].showVisible"
        :data="promotionTagInfos && promotionTagInfos[currentGoodsId]"
        :current-index="currentIndex"
      />
      <ColorDesc
        v-if="showGoodsDesc"
        :goods-desc="goodsDesc"
        :language="language"
      />
      <LargeImageColorDrawer
        v-if="cacheSupportLargeImageList"
        :visible="showLargeImgDrawer"
        :color-title="currColorInfo.name"
        :list="colors"
        :selected-color-id="currentGoodsId"
        :language="language"
        :show-eco="showEco"
        :colors-comp-data="colorsCompData"
        :load-conf="loadConf"
        :current-index="currentIndex"
        :fixed-ratio="fixedRatio"
        :is-support-crop-image="isSupportCropImage"
        @switch-color="handleChangeColor"
        @close="showLargeImgDrawer=false"
      />
    </ClientOnly>
  </div>
</template>

<script>
import 'public/src/icon/check.svg'
import 'public/src/icon/arrow-down.svg'

import _ from 'lodash'
import { abtservice } from 'public/src/services/abt'
import dprInsertMixin from '../../utils/dprInsertMixins'

import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { transformImg, preloadImgs, debounce } from '@shein/common-function'

import ColorPromotionEntry from './ColorPromotionEntry.vue'
import ColorDesc from './ColorDesc'
import ColorSkeleton from './ColorSkeleton'
import LargeImageColorDrawer from './LargeImageColorDrawer'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue3'
const { IS_RW = false } = gbCommonInfo || {}

let FindMyShadeInstance = {}

if (typeof window !== 'undefined') {
  import('public/src/pages/components/find_shade/index.js').then(res => {
    FindMyShadeInstance = res.default
  })
}

import { ClientOnly } from '@sheinfe/vue-client-only'

daEventCenter.addSubscriber({ modulecode: '1-8-6' })
daEventCenter.addSubscriber({ modulecode: '1-8-4' })

const defaultColorConfig = {
  colorItemType: 'radio',
  imgField: 'color_image'
}

export default {
  name: 'ProductIntroColor',
  directives: { expose },
  components: {
    ColorPromotionEntry,
    LargeImageColorDrawer,
    ColorDesc,
    ColorSkeleton,
    ClientOnly,
    Icon,
    CropImageContainer
  },
  mixins: [dprInsertMixin],
  props: {
    cdnUrl: {
      type: String,
      default: ''
    },
    webClient: {
      type: String,
      default: ''
    },
    productIntroData: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    pageComponents: {
      type: Object,
      default: () => null
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    loadConf: {
      type: Object,
      default: () => ({
        size: '750x',
        num: 1
      })
    },
    goodsDesc: {
      type: String,
      default: ''
    },
    colorsCompData: {
      type: Object,
      default() {
        return {}
      },
    },
    fixedRatio: {
      type: String,
      default: '3-4'
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    showAttrImgPopover: {
      type: Boolean,
      default: false
    },
    closePopover: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      IS_RW,
      findShadeRuleId: '',
      showFindMyShadeEntry: false,
      colors: [],
      selectPop: false,
      showMoreColor: false,
      desWidth: 0,
      showEco: true,
      showLargeImgDrawer: false,
      showGoodsDesc: false, // 颜色描述是否展示
      // * large-image-cache 是否支持颜色图片列表弹窗展示，缓存结果
      // 目的是实现在加车弹窗等位置切换商品时保持大图选色窗口仍至于前台
      cacheSupportLargeImageList: false,
      // 缓存一下气泡状态，防止切换skc的时候闪烁气泡
      showAttrImgPopoverCache: false,
      isComplete: false
    }
  },
  computed: {
    soldoutGoodsIds () {
      let soldoutColor = this.productIntroData.soldoutColor
      let mallCode = this.productIntroData.mallCode
      if (!soldoutColor.length) return {}
      let result = {}
      soldoutColor.forEach(item => {
        let mall = item.mall_stock.find(mall => mall.mall_code === mallCode)
        mall &&
          (mall.stock === 0 || Number(item.is_on_sale) === 0) &&
          (result[item.goods_id] = true)
      })
      return result
    },
    currentIndex() {
      return this.colors?.findIndex((item) => item.goods_id === this.currentGoodsId)
    },
    promotionTagInfos() {
      return this.colorsCompData?.promotionTagInfos || {}
    },
    tagId() {
      const { webClient } = this
      return webClient == 'shein' ? '60008057' : '60004511'
    },
    showColorMoreIcon () {
      const { detail = {} } = this.productIntroData
      return detail?.mainSaleAttrShowMode == 2
    },
    showColors() {
      return (
        this.productIntroData?.detail &&
        ((this.productIntroData.detail.mainSaleAttrShowMode == 1 &&
          this.colorsTotal >= 1) ||
          this.colorsTotal > 1 ||
          (this.colorsTotal === 1 && this.showAttrImgPopoverCache && this.currColorInfo?.attr_image))
      )
    },
    // SSR 避开复杂计算
    colorsTotal () {
      const { currentGoodsId, relation_color = [], soldoutColor = [] } = this.productIntroData
      let total = 1, soldout = 0
      if (soldoutColor?.length) {
        const isEqual = soldoutColor.find(_ => _.goods_id == currentGoodsId)
        soldout = isEqual ? soldoutColor.length - 1 : soldoutColor.length
      }
      total += soldout + relation_color.length
      return total
    },
    currentGoodsId() {
      return Number(this.productIntroData?.detail?.goods_id) || ''
    },
    colorConf() {
      const { pageComponents, productIntroData } = this
      if (pageComponents?.colorConf) {
        return pageComponents.colorConf
      }

      const cccTemplateContent =
        productIntroData?.cccTemplateData?.content?.[0]
      if (cccTemplateContent) {
        return {
          colorItemType: cccTemplateContent.colorType == 2 ? 'block' : 'radio',
          imgField:
            cccTemplateContent.colorType == 2 ? 'goods_thumb' : 'color_image'
        }
      }

      return defaultColorConfig
    },
    currColorInfo() {
      const mainSaleAttr =
        this.productIntroData?.detail?.mainSaleAttribute?.[0] || {}
      let val = this.colorType !== 'text' ? `: ${mainSaleAttr.attr_value || ''}` : ''
      return {
        name: mainSaleAttr.attr_name || '',
        val,
        attr_value_id: mainSaleAttr.attr_value_id || '',
        attr_image: mainSaleAttr?.attr_image || '',
        attr_id: mainSaleAttr.attr_id || ''
      }
    },
    mainAttribute() {
      return this.currColorInfo.attr_value_id
        ? this.currColorInfo.attr_id + '_' + this.currColorInfo.attr_value_id
        : ''
    },
    hotGoodSnList() {
      return this.colors.reduce((res, item) => {
        if (item.isHot && (!this.showEco || !item.isEco)) {
          res.push(item.goods_sn)
        }
        return res
      }, [])
    },
    colorType() {
      return this.productIntroData?.detail?.mainSaleAttrShowMode == 1
        ? 'text'
        : this.colorConf.colorItemType
    },
    newColorArr() {
      const { colorType, colors, getNewColorArr, desWidth } = this
      if (!desWidth) return [colors]
      if (colorType === 'block') {
        return getNewColorArr(Math.floor(desWidth / 61))
      } else if (colorType === 'radio') {
        return getNewColorArr(Math.floor(desWidth / 46))
      } else {
        return [colors]
      }
    },
    hasShowMoreEl() {
      return this.newColorArr.length > 4
    },
    // 是否支持颜色图片列表弹窗展示
    supportLargeImageList() {
      const { productIntroData = {} } = this
      const { moduleRenderConfig } = productIntroData
      if (!Array.isArray(moduleRenderConfig) || !moduleRenderConfig.length) {
        return false
      }
      // 大图展示配置key: mobile_colorselector_enlarge
      const largeImageConfig = moduleRenderConfig.find(
        config => config.sceneKey === 'pc_colorselector_enlarge'
      )
      const imgConfig =
        largeImageConfig?.content?.[0]?.content?.props?.items?.[0]
      if (imgConfig) {
        return true
      }
      return false
    },
  },
  watch: {
    closePopover(val) {
      // 特殊场景提供给外部关闭气泡
      if (val) {
        this.$refs?.colorPopover?.forEach?.(popover => {
          popover?.close?.()
        })
      }
    },
    'productIntroData.detail.goods_id': {
      handler(newValue) {
        if (!this.productIntroData?.hasOwnProperty('relation_color') || typeof window == 'undefined') return
        const isCurrSpu = this.colors.find(_ => _.goods_id == newValue)
        !isCurrSpu && this.initColorsInfo()
        this.showAttrImgPopoverCache = this.showAttrImgPopover
        // 相同商品再次打开也要上报
        this.sendSaExposeGoodsDetailSelectMainattr()
      },
      immediate: true
    },
    'productIntroData.mallCode': {
      handler() {
        this.handleRecentView()
        this.colors = this.colors?.map((item) => {
          const mallCode = this.productIntroData?.mallCode
          item.isSoldoutColor = item?.mallIsSoldOut?.[mallCode]
          return item
        })
      }
    },
    hotGoodSnList(newVal) {
      if (newVal.length) {
        daEventCenter.triggerNotice({
          daId: '1-8-6-61',
          extraData: {
            goods_sn: this.hotGoodSnList.join('_')
          }
        })
      }
    },
    hasShowMoreEl(newVal) {
      if (newVal) {
        daEventCenter.triggerNotice({
          daId: '1-8-6-84'
        })
      }
    },
    // * large-image-cache 更新弹窗渲染状态
    supportLargeImageList(support) {
      const { isDetail, showLargeImgDrawer } = this
      // 非商详页且大图窗口为打开状态，不进行同步
      if (!isDetail && showLargeImgDrawer) {
        return
      }
      this.cacheSupportLargeImageList = support
    }
  },
  mounted() {
    if (this.colors?.length) {
      this.preloadBanner()
      if (this.colors.length > 1) {

        const posKeys =
          this.colors.length >= 5 ? 'BeautyFindation' : ''
        // eslint-disable-next-line @shein-aidc/abt/abt
        abtservice
          .getUserAbtResult({
            posKeys,
          })
          .then(result => {
            const {
              BeautyFindation = {},
            } = result
            if (/^is_pde/.test(BeautyFindation.param)) {
              const matchResult = BeautyFindation.param.match(/rule_id=(.*)/)
              this.findShadeRuleId = (matchResult && matchResult[1]) || ''
              this.getShadeShowFlag()
            }

            this.showGoodsDesc = true
          })
      }
    }
    this.handleRecentView()
    this.getDesWidth()
    window.onresize = () => {
      this.getDesWidth()
    }
    // * large-image-cache 将支持大图展示缓存
    this.cacheSupportLargeImageList = this.supportLargeImageList
  },
  methods: {
    initColorsInfo() {
      const hotColorList = _.cloneDeep(
        this.productIntroData?.hotColorList || {}
      )
      const soldoutColor = _.cloneDeep(
        this.productIntroData?.soldoutColor || []
      )
      const temp = [
        _.cloneDeep(this.productIntroData?.detail || {}),
        ..._.cloneDeep(this.productIntroData?.relation_color || []),
        ...soldoutColor
      ] // 当前商品与关联商品数据合并
      const colors = []
      const goodsList = {}
      for (const item of temp) {
        if (goodsList[item.goods_id]) continue // 售罄的颜色可能和已有的重复
        goodsList[item.goods_id] = item.goods_sn
        const goodsTag = hotColorList[item.goods_id] || {}
        const colorInfo = item.mainSaleAttribute?.[0] || {}
        const mallCode = this.productIntroData?.mallCode
        const { mall_stock, stock, is_on_sale } = item || {}
        const mallIsSoldOut = {}
        if (this.productIntroData?.screenAbtConfig?.mallchange?.param?.mallshow === 'auto') {
          const hasStockMall = !!mall_stock?.find(_ => _?.stock > 0)
          mall_stock?.forEach?.((_mall) => {
            mallIsSoldOut[_mall.mall_code] = !!(!hasStockMall || Number(is_on_sale) === 0 || Number(stock) === 0)
          })
        }else {
          mall_stock?.forEach?.((_mall) => {
            mallIsSoldOut[_mall.mall_code] = !!(_mall.stock == 0 || Number(is_on_sale) === 0 || Number(stock) === 0)
          })
        }
        if (colorInfo.attr_id) {
          const color = {
            goods_id: Number(item.goods_id),
            attr_id: (colorInfo && colorInfo.attr_id) || '',
            attr_value: (colorInfo && colorInfo.attr_value) || '',
            attr_value_id: (colorInfo && colorInfo.attr_value_id) || '',
            sort: colorInfo || {
              attr_sort: Infinity,
              value_sort: 0,
              attr_value_id: Infinity
            },
            // 可优化空间,跟sort引用的同一份对象，后续重构可以合并掉
            mainSaleAttribute: colorInfo || {},
            goods_color_image: item[this.colorConf.imgField],
            color_image: item.color_image,
            goods_img: item.goods_img,
            goods_url_name: item.goods_url_name,
            cat_id: item.cat_id,
            productRelationID: item.productRelationID,
            goods_sn: item.goods_sn,
            salePrice: item.salePrice || null,
            isSoldoutColor: mallIsSoldOut[mallCode] || !!this.soldoutGoodsIds[item.goods_id],
            mallIsSoldOut,
            isHot: goodsTag?.hot_color == 1,
            isEco: goodsTag?.tag?.indexOf?.(this.tagId) > -1,
            stock: Number(item.is_on_sale) === 0 ? 0 : Number(item.stock),
            skc_sort: this.productIntroData['skcSort'][item.goods_sn] || 0
          }
          colors.push(color)
          if (item.goods_id == this.productIntroData.detail.goods_id) {
            this.$emit('currentSelectedColor', color)
          }
        }
      }
      this.setColorSort(colors)
      this.$emit('handleData', {
        colorsInfo: this.colors
      })
      // preload
      this.preloadBanner()
      // 保存数据到本地
      this.handleRecentView()
      this.isComplete = true
    },
    setColorSort(colorList) {
      // 需求变更，排序规则按 SKC排序值正序>attr_sort正序 > value_sort倒序 > attr_value_id正序 > goods_id正序

      this.colors = colorList.sort((a, b) => {
        if (a.isSoldoutColor && !b.isSoldoutColor) return 1
        if (!a.isSoldoutColor && b.isSoldoutColor) return -1
        return a.skc_sort - b.skc_sort ||
          a.sort.attr_sort - b.sort.attr_sort ||
          b.sort.value_sort - a.sort.value_sort ||
          a.sort.attr_value_id - b.sort.attr_value_id ||
          a.goods_id - b.goods_id
      })
    },
    async preloadBanner() {
      const goods = this.colors.reduce((res, curr) => {
        res.push({ goods_id: curr.goods_id })
        return res
      }, [])
      const res = await itemSer.getAtomicInfo({
        goods,
        fields: { detailImage: true }
      })
      const imgList = []
      const { size, num, mode } = this.loadConf
      res.forEach(item => {
        if (item?.main_image?.origin_image) {
          imgList.push(
            this.transformImg({
              img: this.dprInsert(item.main_image.origin_image, size, mode)
            })
          )
        }
        if (item?.detail_image?.length) {
          for (let i = 0; i < num; i++) {
            imgList.push(
              this.transformImg({
                img: this.dprInsert(
                  item?.detail_image?.[i]?.origin_image,
                  size,
                  mode
                )
              })
            )
          }
        }
      })
      this.$nextTick(() => {
        preloadImgs({ imgs: imgList })
      })
    },
    async getShadeShowFlag() {
      const goodsId = this.productIntroData.detail.goods_id
      const result = await itemSer.getAtomicInfo({
        goods: [{ goods_id: goodsId }],
        fields: { tspLabels: 'tag' },
        needAssign: false
      })
      if (result?.[goodsId]) {
        const data = result[goodsId].tspLabels || {}
        if (data.tag) {
          this.showFindMyShadeEntry = data.tag.indexOf('60003778') > -1

          this.showFindMyShadeEntry &&
            daEventCenter.triggerNotice({
              daId: '1-8-4-5'
            })
        }
      }
    },
    handleClickFindMyShade() {
      FindMyShadeInstance.show({
        ruleId: this.findShadeRuleId,
        urlQuery: 'main_attr=' + this.mainAttribute,
        urlFilter: /&?attr_ids=(\d|_)+/g
      })
    },
    popoverOpened() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-197',
        extraData: {
          goods_id: this.currentGoodsId,
          location: this.isDetail ? 'page' : 'popup',
          main_attr: 1,
        }
      })
    },
    /**
     * 绑定切换颜色事件
     */
    handleChangeColor(color, fromLargeImage, index) {
      if (color.goods_id === this.currentGoodsId) return
      this.$nextTick(() => {
        daEventCenter.triggerNotice({
          daId: '1-8-6-4',
          extraData: {
            color: color.goods_id,
            condition: Number(color.isSoldoutColor),
            location: fromLargeImage || !this.isDetail ? 'popup' : 'page',
            image_tp: fromLargeImage ? 'large' : 'small',
            position: index + 1,
            ...(this.loadConf?.showPromotionTagInfo ? {
              promotion_type: this.promotionTagInfos[color.goods_id]?.typeId || '-',
              source: this.loadConf?.source
            } : {})
          }
        })
        this.$emit('switchColor', color)
        if (this.selectPop) {
          this.closeSelectPop()
        }
      })
    },
    handleRecentView() {
      var products = []
      var goods = _.cloneDeep(this.productIntroData?.detail || {})
      var isViewed = false

      if (!goods.goods_id) return

      try {
        products = localStorage.getItem('pc_recent_views')
      } catch (err) {
        // console.log(err)
      }
      if (!products) {
        products = []
      } else {
        products = JSON.parse(products)
      }
      products = products.filter(
        item => item.goodsId.productRelationID != goods.productRelationID
      )
      var goodsInfo = {
        mall_code: this.productIntroData.mallCode
          ? this.productIntroData.mallCode
          : '',
        goodsId: {
          goods_id: goods.goods_id,
          productRelationID: goods.productRelationID,
          goods_sn: goods.goods_sn,
          goods_name: goods.goods_name,
          goods_url_name: goods.goods_url_name,
          cat_id: goods.cat_id,
          salePrice: goods.salePrice,
          retailPrice: goods.retailPrice,
          goods_img: goods.goods_img,
          goods_thumb: goods.goods_thumb,
          promotion: goods.promotion,
          promotionInfo: goods.promotionInfo,
          unit_discount: goods.unit_discount,
          is_on_sale: goods.is_on_sale,
          stock: goods.stock
        },
        goods_sn: goods.goods_sn,
        goods_url: getGoodsUrl(
          goods.goods_url_name,
          goods.goods_id,
          goods.cat_id
        ),
        goods_id: goods.goods_id,
        goods_name: goods.goods_name,
        goods_url_name: goods.goods_url_name,
        goods_img: goods.goods_img,
        is_on_sale: goods.is_on_sale,
        stock: goods.stock,
        //是否支持选中
        is_select: true,
        // 是否显示删除按钮
        showtrash: true,
        exclusivePromotionPrice: goods.exclusivePromotionPrice || null
      }
      products.forEach((item, index) => {
        if (item.goods_id == goods.goods_id) {
          products[index] = goodsInfo
          isViewed = true
        }
      })
      if (!isViewed) {
        products.unshift(goodsInfo)
        if (products.length > 100) {
          products.pop()
        }
      }
      try {
        localStorage.setItem('pc_recent_views', JSON.stringify(products))
      } catch (e) {
        //
      }
    },
    showSelectPop() {
      if (this.colorConf.hasDropPop) {
        if (!this.selectPop) {
          this.selectPop = true
          daEventCenter.triggerNotice({
            daId: '1-8-6-53'
          })
          this.$nextTick(() => {
            document.addEventListener('click', this.closeSelectPop)
          })
        } else {
          this.closeSelectPop()
        }
      }
    },
    closeSelectPop() {
      this.selectPop = false
      document.removeEventListener('click', this.closeSelectPop)
    },
    getNewColorArr(size) {
      const { colors } = this
      const mathLen = Math.ceil(colors.length / size)
      let index = 0,
          arr = [],
          len = mathLen >= 0 ? mathLen : 0,
          newArr
      if (colors.length <= size) return [colors]
      newArr = new Array(len)
      colors.forEach((item, i) => {
        arr.push(item)
        if ((i + 1) % size === 0) {
          newArr[index] = arr
          arr = []
          index++
        }
      })
      if (!newArr[len - 1]) newArr[len - 1] = arr
      return newArr
    },
    updateShowMore() {
      this.showMoreColor && this.isDetail && window?.scrollTo(0, 0)
      this.showMoreColor = !this.showMoreColor
      daEventCenter.triggerNotice({
        daId: '1-8-6-83'
      })
    },
    getDesWidth: debounce({
      func: function () {
        const { isDetail } = this
        if (!isDetail) {
          const quickViewDes = document.querySelector('.quick-view__des')
          this.desWidth = quickViewDes?.offsetWidth
        } else {
          const productIntroInfo = document.querySelector('.product-intro__info')
          this.desWidth = productIntroInfo?.offsetWidth
        }

      },
      wait: 500
    }),
    labelExpose(goods_id) {
      const promotionInfo = this.promotionTagInfos?.[goods_id]
      return {
        id: '1-6-1-141',
        once: true,
        data: {
          promotion_type: promotionInfo?.typeId,
          style: this.loadConf?.source,
          skc: promotionInfo?.goods_sn
        }
      }
    },
    sendSaExposeGoodsDetailSelectMainattr() {
      if (this.colors.length > 1) {
        const { isDetail } = this
        daEventCenter.triggerNotice({
          daId: '1-8-6-24',
          extraData: {
            location: isDetail ? 'page' : 'popup',
            image_tp: 'small',
            ...(this.loadConf?.showPromotionTagInfo ? {
              promotion_type: this.colorsCompData?.promotionTypeId || '-',
              source: this.loadConf?.source
            } : {})
          }
        })
      }
    },
    transformImg
  },
  emits: ['currentSelectedColor', 'switchColor', 'handleData']
}
</script>

<style lang="less">
.product-intro {
  &__size-radio {
    margin: 0 10px 10px 0;
  }
  &__color-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;

    .title {
      font-weight: 700;
    }

    .color-block {
      font-weight: bold;
      display: inline-block;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .color-999 {
      font-weight: normal;
      .padding-l(9px);
      color: #222;
    }
    span {
      font-weight: 400;
    }
  }
  &__color-shade {
    display: inline-block;
    color: #3379cb;
    font-size: 14px;
    margin-left: 16px;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #1860a7;
      text-decoration: none;
    }
  }

  &__color {
    .color-hot {
      position: absolute;
      top: -4px;
      .right(-13px);
      width: 24px;
      height: 13px;
      border-radius: 0;
      z-index: @zindex-hack;
    }
    .color-evolu {
      position: absolute;
      top: -4px;
      .right(-8px);
      width: 19px;
      height: 13px;
      border-radius: 0;
      z-index: @zindex-hack;
    }
    &_choose {
      position: relative;
      overflow: hidden;
      padding-top: 3px;
      &.block {
        height: 34px;
      }
      &.radio {
        height: 18px;
      }
      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 100%
        );
        z-index: @zindex-hack;
      }
    }
    &_showMore {
      margin-top: 15px;
      font-size: 12px;
      color: #1860a7;
      cursor: pointer;
      /* rw:begin*/
      color: #ff9999;
    }
  }

  &__color-select {
    position: absolute;
    top: calc(100% + 10px);
    .left(0);
    width: 320px;
    padding: 5px 0;
    height: auto;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(242, 242, 242, 1);
    z-index: @zindex-hover-tip;
    max-height: 250px;
    overflow: scroll;
  }
  &__color-soldout {
    position: absolute;
    left: 1px;
    top: 50%;
    transform: translate3d(0, -50%, 0) rotate(-45deg);
    transform-origin: center;
    display: block;
    width: 26px;
    height: 2px;
    background: rgba(153, 153, 153, 0.7);
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      top: -1px;
      .left(0);
      background: rgba(255, 255, 255, 0.6);
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1px;
      .left(0);
      background: rgba(255, 255, 255, 0.6);
    }
  }
  &__color-item {
    display: flex;
    height: 44px;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    .color-name {
      display: flex;
      align-items: center;
      color: #222;
      font-size: 14px;
      font-weight: normal;
      .color-soldout-text {
        color: #999;
      }
    }
    .color-img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      .margin-r(10px);
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    .svgicon-check {
      width: 16px;
      height: 16px;
    }
    .color-stock {
      .orange {
        color: #ff7300;
        font-size: 12px;
        font-weight: normal;
      }
      .grey {
        color: #999;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  &__color-block {
    position: relative;
    .margin-r(10px);
    margin-bottom: 10px;
    display: inline-block;
    font-size: 0;
    cursor: pointer;
    width: 51px;
    padding: 3px;
    border: 2px solid transparent;
    &:hover {
      outline: 1px solid #222;
      outline-offset: -1px;
    }
    .product-intro__color-soldout {
      top: auto;
      bottom: 0;
      transform: translate3d(0%, 0%, 0) rotate(-54.8deg);
      transform-origin: left bottom;
      width: 74px;
    }
    .color-inner {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      &:after {
        position: absolute;
        .left(0);
        .right(0);
        top: 0;
        bottom: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        border: 1px solid fade(#000, 8%);
        background: transparent;
      }
    }
    .product-intro__color-soldout-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
    }
    &_active {
      border: 2px solid #222;
    }
  }
  &__color-radio {
    position: relative;
    .margin-r(10px);
    margin-bottom: 10px;
    padding: 3px;
    width: 36px;
    height: 36px;
    line-height: 28px;
    border: 1px solid transparent;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    font-size: 0;
    cursor: pointer;
    &:hover {
      border: 1px solid #222;
      /* rw:begin*/
      border: 1px solid rgba(255, 105, 110, 1);
    }
    .color-inner {
      position: relative;
      width: 28px;
      height: 28px;
      &:after {
        position: absolute;
        .left(0);
        .right(0);
        top: 0;
        bottom: 0;
        display: block;
        content: "";
        width: 28px;
        height: 28px;
        border: 1px solid fade(#000, 8%);
        border-radius: 50%;
        background: transparent;
        /* rw:begin*/
        border: 1px solid transparent;
      }
    }
    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
    &_active {
      .color-inner {
        &:after {
          border: 2px solid fade(#000, 8%);
          /* rw:begin*/
          border: 2px solid transparent;
        }
        .product-intro__color-soldout {
          width: 24px;
          .left(2px);
        }
      }
      &::before {
        position: absolute;
        .left(-1px);
        .right(0);
        top: -1px;
        bottom: 0;
        display: block;
        content: "";
        width: 36px;
        height: 36px;
        border: 2px solid #222;
        border-radius: 50%;
        background: transparent;
        /* rw:begin*/
        border: 2px solid rgba(255, 105, 110, 1);
      }
      &::after {
        position: absolute;
        .left(2px);
        top: 2px;
        display: block;
        content: "";
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 50%;
        background: transparent;
      }
    }
  }
  &__color-popoverTitle {
    font-size: 14px;
    font-weight: 700;
    color: @sui_color_brand;
  }
  &__color-popoverImg {
    margin-top: 8px;
    height: 72px;
  }
  &__tag-discount {
      position: absolute;
      top: -4px;
      .right(-13px);
      transform-origin: 100% 0;
      transform: scale(.625);
      z-index: @zindex-hack;

      border: 1.28px solid rgba(255, 255, 255, 0.86);
      border-radius: 3.2px;
      background: @sui_color_promo_bg;
      padding: 0 3.2px;
      overflow: hidden;

      font-size: 16px;
      line-height: 19.2px;
      color: @sui_color_promo_dark;
      white-space: nowrap;
  }
  &__tag-promotion {
      position: absolute;
      top: -4px;
      .right(-13px);
      transform-origin: 100% 0;
      transform: scale(.625);
      z-index: @zindex-hack;

      border: 1.28px solid rgba(255, 255, 255, 0.86);
      border-radius: 3.2px;
      background: @sui_color_promo_bg;
      padding: 0 3.2px;
      overflow: hidden;

      white-space: nowrap;
  }
}
</style>
